import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Have you always dreamed of building a successfull software company?`}</h3>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Since 2017 Trailguide has developed technology to digitalize outdoor
  activities and help the tourism sector offer better outdoor experiences for
  their guests. This is something we absolutely have succeeded with.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The current team loves to develop great stuff, and that is what we did. We did
  not have the right personalities, abilities, nor the capital or help to build
  a proper company around it.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  We *did* built a great software platform and technology for the activity
  tourism and now we need help to build the company around it.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  If you have access to capital, the right skills, and the right people, we
  would love to hear from you. The right person needs to understand the problem
  domain, understand who the customer is (or can be), and have a deep desire and
  ability to build a multimillion dollar tech company.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Read more at trailguide.no/forsale
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      